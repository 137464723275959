export class Trabajador {
    public tra_id?: number
    public rut?: string
    public nombre?: string
    public paterno?: string
    public materno?: string
    public nacimiento?: string
    public sexo?: string
    public fono?: string
    public mail?: string
    public gerencia?: string
    public cargo?: string
    public antiguedad?: string
    public estado_civil?: string
    public hijos?: number
    public creacion?: Date
    public modificado?: Date
    public params?: any
    public fichas?: any
    public evaluacionesMutual?: any
}
