import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppViewComponent } from './app/app-view/app-view.component';
import { FichasAdminComponent } from './fichas/fichas-admin/fichas-admin.component';
import { InscriptionAdminComponent } from './inscription/inscription-admin/inscription-admin.component';
import { LibraryAdminComponent } from './library/library-admin/library-admin.component';
import { LibraryViewComponent } from './library/library-view/library-view.component';
import { MainLayoutComponent } from './page/main-layout/main-layout.component';
import { NotFoundComponent } from './page/not-found/not-found.component';
import { UnityWebglContainerComponent } from './unity-webgl-container/unity-webgl-container.component';


const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    children: [
      { path: "", redirectTo:'/reportes', pathMatch:"full"},
      { path: 'library', component: LibraryAdminComponent },
      { path: "unity", component: UnityWebglContainerComponent },
      { path: "reportes", component: FichasAdminComponent },
      { path: "inscriptions", component: InscriptionAdminComponent }
    ]
  },
  {
    path: 'app',
    component: AppViewComponent
  },
  {
    path: '**',
    pathMatch: 'full',
    component: NotFoundComponent
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
