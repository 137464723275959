<!-- <pre>{{model|json}}</pre> -->
<!-- {{progress}} -->
<div class="row">

    <div class="col-12" *ngIf="progress!=1">
        <p>
            <ngb-progressbar type="primary" [value]="progress*100"></ngb-progressbar>
        </p>
    </div>
    <div class="col-12 mb-2" *ngIf="progress==1">
        <button class="btn btn-primary" (click)="unitySevice.fullScreen()">Pantalla Completa</button>
        <button class="btn btn-primary" (click)="unitySevice.Quit();progress=0">Cerrar Aplicacion</button>
    </div>
    <div class="col-12" [ngbCollapse]="progress!=1">
        <div class="embed-responsive embed-responsive-16by9">
            <div id="unityContainer" class="unity-container embed-responsive-item"></div>
        </div>
    </div>
</div>