import { HostListener, Injectable, OnInit } from '@angular/core';

const UnityLoader = window["UnityLoader"];

@Injectable({
  providedIn: 'root'
})
export class UnityService {

  private gameInstance: any;

  constructor() {
    window['receiveMessageFromUnity'] = this.receiveMessageFromUnity;
  }

  public load(componentId: string, url: string, opt?: {
    onProgress: (intance: any, progress?: number) => void,
    Module?: {
      onQuit?: () => void,
      errorHandler?: (error) => void
    }
  }) {
    if (this.gameInstance) {
      this.Quit()
    }
    // window["UnityLoader"] = UnityLoader;
    this.gameInstance = UnityLoader.instantiate(componentId, url, opt);
  }

  public receiveMessageFromUnity(messageValue: string) {
    console.log('receiveMessageFromUnity', messageValue);
  }


  public sendMessage(messageHandler: string, message: any) {
    this.gameInstance.SendMessage(messageHandler, message.type, JSON.stringify(message.payload));
  }

  public fullScreen() {
    this.gameInstance.SetFullscreen(1)
  }

  public Quit() {
    this.gameInstance.Quit()
    this.gameInstance = null;
  }
}
