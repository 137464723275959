import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  constructor(private http: HttpClient, private auth: AuthService) { }

  private base = `${environment.endpoint}/report`;

  getFichaById(id) {
    const headers = { Authorization: `${this.auth.session.token_type} ${this.auth.session.token}` };
    return this.http.get(`${this.base}/empresa/ficha/${id}`, { headers, responseType: 'arraybuffer' });
  }
}
