import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { Contratista } from '../../class/contratista';
import { Evaluacion } from '../../class/evaluacion';
import _ from "lodash-es";
import { Project } from '../../_class/project';
@Component({
  selector: 'app-inscription-search',
  templateUrl: './inscription-search.component.html',
  styleUrls: ['./inscription-search.component.scss']
})
export class InscriptionSearchComponent implements OnInit {
  @Input() projects: Project[]

  @Output() search: EventEmitter<any> = new EventEmitter();

  cedula: string
  nombre: string
  paterno: string
  materno: string
  status: string
  sede: string
  conId: number
  proId: number
  curso: number
  from: NgbDate
  to: NgbDate

  constructor() { }

  ngOnInit(): void {
  }


  onSearch() {
    var criteria = {
      cedula: this.cedula,
      nombre: this.nombre,
      paterno: this.paterno,
      materno: this.materno,
      status: this.status,
      conId: this.conId,
      curso: this.curso,
      sede: this.sede,
      proId: this.proId,
      from: this.from,
      to: this.to
    }

    this.search.emit(_(criteria).pickBy(_.identity).value());
  }

}
