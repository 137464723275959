import { HttpParams, HttpHeaders } from '@angular/common/http';

interface PaginationOptions {
    page?: number;
    perPage?: number;
    total?: number;
    fields?: string[];
    expand?: string[];
    sort?: string;
    params?: HttpParams;
}


export class Pagination {

    public page = 1;
    public perPage = 10;
    public fields: string[] = [];
    public expand: string[] = [];
    private _params: HttpParams = new HttpParams();

    public sort?: string;
    public total?: number;

    constructor(fields?: PaginationOptions) {
        fields && Object.assign(this, fields);
    }

    set params(fields: HttpParams) {
        this._params = fields;
    }

    get params(): HttpParams {
        let p = this._params;
        if (this.page) { p = p.set('page', this.page.toString()); }
        if (this.perPage) { p = p.set('per-page', this.perPage.toString()); }
        if (this.sort) { p = p.set('sort', this.sort); }
        if (this.fields.length > 0) { p = p.set('fields', this.fields.join(',')); }
        if (this.expand.length > 0) { p = p.set('expand', this.expand.join(',')); }
        return p;
    }

    toString() {
        return this.params.toString();
    }

    Load(headers: HttpHeaders) {
        if (headers.get('X-Pagination-Total-Count')) { this.total = parseInt(headers.get('X-Pagination-Total-Count')); }
        if (headers.get('X-Pagination-Current-Page')) { this.page = parseInt(headers.get('X-Pagination-Current-Page')); }
        if (headers.get('X-Pagination-Per-Page')) { this.perPage = parseInt(headers.get('X-Pagination-Per-Page')); }
    }

    AddParam(key: string, value: string) {
        this._params = this._params.set(key, value);
    }
    
    AddParams(key: string, value: string) {
        this._params = this._params.append(key, value);
    }

    DelParams(key) {
        this._params = this._params.delete(key);
    }

    get detail() {
        return `Mostrando registros del ${this.init + 1} al ${this.end + 1} de un total de ${this.total} registros.`;
    }

    get init() {
        return (this.page - 1) * this.perPage;
    }

    get end() {
        return (this.page < this.total / this.perPage) ? this.page * this.perPage - 1 : this.total - 1;
    }
}
