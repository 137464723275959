import { Component, EventEmitter, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Contratista } from '../../class/contratista';
import { Pagination } from '../../_class/pagination';
import { Project } from '../../_class/project';
import { ProjectInscription } from '../../_class/project-inscription';
import { AuthService } from '../../_services/auth.service';
import { TransversalService } from '../../_services/transversal.service';
import * as  moment from "moment";
import { HttpParams } from '@angular/common/http';
import * as _ from 'lodash';
declare interface PromiseConstructor {
  allSettled(promises: Array<Promise<any>>): Promise<Array<{ status: 'fulfilled' | 'rejected', value?: any, reason?: any }>>;
}
@Component({
  selector: 'app-inscription-admin',
  templateUrl: './inscription-admin.component.html',
  styleUrls: ['./inscription-admin.component.scss']
})
export class InscriptionAdminComponent implements OnInit {

  showSearch = false;

  models: ProjectInscription[] = [];

  model: ProjectInscription

  projects: Project[] = []
  contratistas: Contratista[] = []


  page: Pagination = new Pagination({
    total: 1,
    sort: '-id',
    expand: ['project'],
    perPage: 50
  });

  constructor(
    public toastr: ToastrService,
    private authService: AuthService,
    private modalService: NgbModal,
    private transversalService: TransversalService
  ) { }

  subLogin
  ngOnInit(): void {
    if (this.authService.isValid) {
      this.getFirstData();
      this.transversalService.GetProject().subscribe(({ body }) => { this.projects = body })
      // this.axaService.getContratistas().subscribe(data => {
      //   this.contratistas = data;
      // })
      // this.axaService.getEvaluaciones({ expand: "params" }).subscribe(data => {
      //   this.evaluaciones = data
      // })
    }

    if (this.subLogin) {
      this.subLogin.unsuscribe();
    }

    this.subLogin = this.authService.onLoginSuccess.subscribe(() => {
      this.getFirstData();
      this.transversalService.GetProject().subscribe(({ body }) => { this.projects = body })
      // this.axaService.getContratistas().subscribe(contratistas => {
      //   this.contratistas = contratistas;
      // })
      // this.axaService.getEvaluaciones({ expand: "params" }).subscribe(data => {
      //   this.evaluaciones = data
      // })
    });
  }

  async getFirstData() {
    this.page.page = 1;
    this.page.perPage = 20;
    const { headers, body } = await this.transversalService.GetInscription(this.page.params).toPromise()
    this.page.Load(headers);
    this.models = body;
  }

  async onScroll() {
    if (this.models.length < this.page.total) {
      this.page.page++;
      const { body, headers } = await this.transversalService.GetInscription(this.page.params).toPromise();
      this.page.Load(headers);
      this.models.push(...body);
    } else {
      this.toastr.info("Se ha cargado el ultimo reporte");
    }
  }

  search(criteria) {
    var params = new HttpParams();
    if (criteria.cedula) {
      params = params.set('filter[traRut][like]', criteria.cedula)
    }
    if (criteria.nombre) {
      // params = params.set('filter[params][ficha.trabajador.nombre][like]', criteria.nombre)
      params = params.set('filter[traNombre][like]', criteria.nombre)
    }
    if (criteria.paterno) {
      params = params.set('filter[traPaterno][like]', criteria.paterno)
    }
    if (criteria.materno) {
      params = params.set('filter[traMaterno][like]', criteria.materno)
    }
    if (criteria.curso) {
      params = params.set('filter[parCurso][like]', criteria.curso)
    }
    if (criteria.sede) {
      params = params.set('filter[parSede][like]', criteria.sede)
    }
    if (criteria.conId) {
      params = criteria.conId.reduce((acc, cur) => acc.append("filter[conId][in][]", cur.toString()), params)
    }
    if (criteria.proId) {
      params = criteria.proId.reduce((acc, cur) => acc.append("filter[proId][in][]", cur.toString()), params)
    }
    if (criteria.evaId) {
      params = criteria.evaId.reduce((acc, cur) => acc.append("filter[evaId][in][]", cur.toString()), params)
    }
    if (criteria.from) {
      var { year, month, day } = criteria.from;
      params = params.set('filter[creado][gte]', moment(`${year}-${month}-${day}`, 'YYYY-MM-DD').format('YYYY-MM-DD'))
    }
    if (criteria.to) {
      var { year, month, day } = criteria.to;
      params = params.set('filter[creado][lte]', moment(`${year}-${month}-${day}`, 'YYYY-MM-DD').add(1, "day").format('YYYY-MM-DD'))
    }
    console.log({ params })
    this.page.params = params;
    this.getFirstData();
  }

  sort = {
    name: 'id',
    order: false,
    menu: [
      { sort: 'traRut', title: 'Cédula de ciudadania' },
      { sort: 'traNombre', title: 'Nombre' },
      { sort: 'parSede', title: 'Sede' },
      { sort: 'proId', title: 'Curso' },
      { sort: 'parCurso', title: 'Codigo del Cuso' },
      { sort: 'parMailStatus', title: 'Estado' },
      { sort: 'creado', title: 'Creado' },
      { title: 'Opciones' }
    ]
  };

  setOrder(prop: string) {
    if (this.sort.name != prop) {
      this.sort.name = prop;
      this.sort.order = true;
    } else {
      this.sort.order = !this.sort.order;
    }
    this.page.page = 1;
    this.page.sort = this.sort.order ? this.sort.name : '-' + this.sort.name
    this.getFirstData()
  }

  iconOrder(prop: string) {
    if (this.sort.name == prop) {
      return this.sort.order ? ['fas', 'sort-down'] : ['fas', 'sort-up'];
    } else {
      return ['fas', 'sort'];
    }
  }

  trackBy(index: number, item: ProjectInscription): number {
    return item.id;
  }

  subModal: NgbModalRef;

  open(content, opt?) {
    this.subModal = this.modalService.open(content, opt)
  }

  close() {
    this.subModal.close();
  }

  loading = {
    collapse: true,
    current: 0,
    total: 0,
    fail: 0,
    text: () => {

    },
    tripped: true,
    animated: true,
    type: "primary"
  }

  inscriptionsMailFail = []

  closeShowProgress() {
    this.loading.collapse = true;
  }

  async save(models: ProjectInscription[]) {
    try {
      this.inscriptionsMailFail = []
      this.close();
      this.loading = {
        collapse: false,
        current: 0,
        fail: 0,
        total: models.length,
        text: () => {
          return `${this.loading.current}/${this.loading.total}`
        },
        tripped: true,
        animated: true,
        type: "primary"
      }

      var result = await Promise.all(models.map(async x => {
        try {
          var inscription = await this.transversalService.CreateInscription(x).toPromise();
          var shareMail = await this.transversalService.ShareMail(inscription.id).toPromise();
          this.loading.current += 1;
          await this.transversalService.UpdateInscription(inscription.id, { setval: { 'ficha.params.mail.status': 'VALIDO' } }).toPromise();
        } catch (error) {
          this.loading.current += 1;
          this.loading.fail += 1;
          this.inscriptionsMailFail.push({ inscription, shareMail, error })
          await this.transversalService.UpdateInscription(inscription.id, { setval: { 'ficha.params.mail.status': 'ERROR', 'ficha.params.mail.error': error.error } }).toPromise();
        }
      }));

      this.loading.text = () => "Completado"
      this.loading.tripped = false;
      this.loading.animated = false;
      this.loading.type = "success";

      this.getFirstData();
    } catch (error) {
      this.toastr.error('Ha ocurrido un problema')
    }
  }

  async disable() {
    await this.transversalService.UpdateInscription(this.model.id, { status: 0 }).toPromise();
    this.close()
    this.getFirstData();
  }
}
