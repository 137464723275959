<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Crear Inscripción</h4>
    <button type="button" class="close" aria-label="Close" (click)="close.emit()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form #formInscription="ngForm">
        <fieldset class="mb-3">
            <legend>Información Personal</legend>
            <div class="form-row">
                <div class="form-group col-md-6">
                    <label for="traRut">Cédula Ciudadania</label>
                    <input type="text" class="form-control" id="traRut" name="traRut"
                        placeholder="Ingrese la cédula de ciudadania ..."
                        [(ngModel)]="model.content.ficha.trabajador.rut">
                </div>
                <div class="form-group col-md-6">
                    <label for="email">Email</label>
                    <input type="email" class="form-control" id="email" name="email" placeholder="Ingrese el correo ..."
                        [(ngModel)]="model.content.ficha.trabajador.mail">
                </div>
                <div class="form-group col-md-4">
                    <label for="traNombre">Nombre</label>
                    <input type="text" class="form-control" id="traNombre" name="traNombre"
                        placeholder="Ingrese la nombre ..." [(ngModel)]="model.content.ficha.trabajador.nombre">
                </div>
                <div class="form-group col-md-4">
                    <label for="traPaterno">Apellido Paterno</label>
                    <input type="text" class="form-control" id="traPaterno" name="traPaterno"
                        placeholder="Ingrese la apellido paterno ..."
                        [(ngModel)]="model.content.ficha.trabajador.paterno">
                </div>
                <div class="form-group col-md-4">
                    <label for="traMaterno">Apellido Materno</label>
                    <input type="text" class="form-control" id="traMaterno" name="traMaterno"
                        placeholder="Ingrese la apellido materno ..."
                        [(ngModel)]="model.content.ficha.trabajador.materno">
                </div>
            </div>
        </fieldset>
        <fieldset class="mb-3">
            <legend>Información de la Simulación</legend>
            <div class="form-row">
                <div class="form-group col-md-12">
                    <label for="projecto">Simulación</label>
                    <ng-select [(ngModel)]="model.content.ficha.params.proId" [(ngModel)]="model.proId" name="projecto"
                        placeholder="Seleccione la simulación ...">
                        <ng-option *ngFor="let model of projects" [value]="model.id">
                            {{model.header}}</ng-option>
                    </ng-select>
                </div>
                <div class="form-group col-md-6">
                    <label for="parSede">Sede</label>
                    <input type="text" class="form-control" id="parSede" name="parSede"
                        placeholder="Ingrese la sede ..." [(ngModel)]="model.content.ficha.params.sede">
                </div>

                <div class="form-group col-md-6">
                    <label for="parCurso">Codigo del Curso</label>
                    <input type="text" class="form-control" id="parCurso" name="parCurso"
                        placeholder="Ingrese el codigo del curso ..." [(ngModel)]="model.content.ficha.params.curso">
                </div>
            </div>
        </fieldset>
        <fieldset>
            <legend>Carga Masiva</legend>
            <div class="form-row">
                <div class="form-grou col">
                    <button class="btn btn-success btn-block mb-2" (click)="downloadExcel()">
                        <fa-icon [icon]="['fas','file-excel']"></fa-icon> Descargar Plantilla
                    </button>
                </div>
                <div class="form-group col-md-9">
                    <div class="custom-file">
                        <input type="file" class="custom-file-input" id="exampleInputFile"
                            (change)="onFile($event.target.files)" accept=".xlsx, .xls">
                        <label class="custom-file-label" for="exampleInputFile" data-browse="Seleccionar">Selecciona el
                            archivo excel para carga masiva ... </label>
                    </div>
                </div>

            </div>
            <div class="table-responsive">
                <table class="table table-bordered table-sm">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Alumno</th>
                            <th>Incripción</th>
                            <th>Estado</th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-template ngFor let-item [ngForOf]="models" let-i="index">
                            <tr>
                                <td rowspan="3">{{i+1}}</td>
                                <td><strong>Cédula de Ciudadanía:</strong> {{item.content.ficha.trabajador.rut}}</td>
                                <td><strong>Curso:</strong> {{findProject({id:item.proId})?.header}}</td>
                                <td rowspan="3">
                                    <ul class="list-group list-group-flush" *ngFor="let error of getErrors(item)">
                                        <li class="list-group-item"
                                            [ngClass]="{'list-group-item-success':error=='OK','list-group-item-warning': error != 'OK'}">
                                            {{error}}</li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <td><strong>Nombre:</strong>
                                    {{item.content.ficha.trabajador.nombre}}
                                    {{item.content.ficha.trabajador.materno}}
                                    {{item.content.ficha.trabajador.paterno}}
                                </td>
                                <td><strong>Código del Curso:</strong> {{item.content.ficha.params.curso}}</td>
                            </tr>
                            <tr>
                                <td><strong>Correo:</strong> {{item.content.ficha.trabajador.mail}}</td>
                                <td><strong>Sede:</strong> {{item.content.ficha.params.sede}}</td>
                            </tr>
                        </ng-template>
                    </tbody>
                </table>
            </div>
        </fieldset>
    </form>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-primary" [disabled]="hasError"
        (click)="save.emit(models.length>0?models:[model])">Inscribir</button>
</div>