import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LibraryViewComponent } from '../../library/library-view/library-view.component';
import { Project } from '../../_class/project';
import { ProjectInscription } from '../../_class/project-inscription';
import { TransversalService } from '../../_services/transversal.service';
import { UnityService } from '../../_services/unity.service';
import * as _ from 'lodash'

@Component({
  selector: 'app-app-view',
  templateUrl: './app-view.component.html',
  styleUrls: ['./app-view.component.scss']
})
export class AppViewComponent implements OnInit {

  Autorization = "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1aWQiOjEsInN1YiI6ImVqVGkiLCJpYXQiOjE2MDgyNDAzNTEsIm9pZCI6NzZ9.MYQSciZu1eYiUNaugWm_U-fzOQcVwk9CzB6vlBMzfZc"

  accessToken;

  collapse = false;
  project: Project
  inscription: ProjectInscription;


  @ViewChild(LibraryViewComponent)
  private viewComponent: LibraryViewComponent;

  constructor(
    private route: ActivatedRoute,
    private transversalService: TransversalService,
    private UnityService: UnityService
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(async params => {
      this.accessToken = params['access-token']
      this.inscription = await this.transversalService.GetToken(this.Autorization, {
        expand: 'project.builds',
        'access-token': this.accessToken
      }).toPromise()
      this.project = this.inscription.project

    })
  }

  start() {
    var lastBuild = _(this.project.builds).filter({enviroment:"PRODUCTION"}).maxBy('creado')
    this.viewComponent.loadProject(lastBuild.content.configFile)
  }

}
