import { Component, OnDestroy, OnInit } from '@angular/core';
import { Evaluacion } from '../../class/evaluacion';
import { Ficha } from '../../class/ficha';
import { FichaFilter } from '../../class/filter/ficha-filter';
import { Pagination } from '../../class/pagination';
import { AuthService } from '../../_services/auth.service';
import { SenaService, SenaState } from '../../_services/sena.service';
import * as _ from 'lodash';
import { HttpParams } from '@angular/common/http';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TrabajadorService } from '../../_services/trabajador.service';
import { Contratista } from '../../class/contratista';
import { ToastrService } from 'ngx-toastr';
import { ReportService } from '../../_services/report.service';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import * as FileSaver from 'file-saver';
import { FichaParamsService } from '../../_services/ficha-params.service';
import { FichaParams } from '../../class/ficha-params';

@Component({
  selector: 'app-fichas-admin',
  templateUrl: './fichas-admin.component.html',
  styleUrls: ['./fichas-admin.component.scss']
})
export class FichasAdminComponent implements OnInit, OnDestroy {

  fichas : Ficha[];
  fichasShowed : Ficha[];
  loginRef : Subscription;
  evaluaciones :Evaluacion[];
  contratistas:Contratista[];
  fichaFilter: FichaFilter = new FichaFilter();
  isSearchCollapsed = true;
  fichaTemp : Ficha;
  modalRef:NgbModalRef;
  data: SenaState = null;
  toastrRef;
  page: Pagination = new Pagination({
    total: 1,
    page:1,
    perPage:50,
    sort: '-fic_id',
    expand: ['trabajador', 'params', 'evaluacion','alternativas', 'params']
  });

  //#region Sorteable Table
    sort = {
      name: null,
      order: true,
      menu: [
        { sort: 'ficId', title: 'N° Ficha'},
        { sort :'sede', title: 'Sede'},
        { sort :'curso', title: 'ID Curso'},
        { sort: 'evaId', title: 'Curso'},
        { sort: 'traRut', title: 'N° de Ciudadanía'},
        { sort: 'traNombre', title: 'Nombre'},
        { sort: 'traPaterno', title: 'Paterno'},
        { sort: 'traMaterno', title: 'Materno'},
        { sort: 'traMail', title: 'Correo'},
        // { sort: 'conId', title: 'Empresa'},
        { sort: 'calificacion', title: 'Calificación'},
        { sort: 'creado', title: 'Fecha' }
      ]
    };

  constructor(
    private sessionService:AuthService,
    private senaService:SenaService,
    private modal:NgbModal,
    private trabajadorService:TrabajadorService,
    private toastService: ToastrService,
    private reportService: ReportService,
    // private contratistaService: ContratistaService,
    // private fichaService:FichaService,
    private fichaParamsService:FichaParamsService
    ) { }

  ngOnInit(): void {
    if (this.sessionService.isValid) {
      this.init();
    }

    this.loginRef = this.sessionService.onLoginSuccess.subscribe(({ name }) => {
      this.init();
    });
  }

  ngOnDestroy(){
    this.loginRef.unsubscribe();
  }

  init(){
    this.getFichas();
    this.getEvaluaciones();
    this.getContratistas();
  }

  getFichas(){
    this.senaService.GetFichas(this.page.params)
      .subscribe(data=>{
        this.fichas=data.body
        this.page.Load(data.headers);
      })
  }

  getEvaluaciones(){
    this.senaService.GetEvaluaciones()
      .subscribe(data=>{
        this.evaluaciones = data.body;
      })
  }

  getContratistas(){
    this.senaService.GetContratistas()
      .subscribe(response =>{
        this.contratistas = response.body;
      })
  }

  setOrder(prop: string) {
    if (this.sort.name != prop) {
      this.sort.name = prop;
      this.sort.order = true;
    } else {
      this.sort.order = !this.sort.order;
    }
    this.page.page = 1;
    this.page.sort = this.sort.order ? this.sort.name : '-' + this.sort.name
    this.init()
  }

  iconOrder(prop: string) {
    if (this.sort.name == prop) {
      return this.sort.order ? ['fas', 'sort-down'] : ['fas', 'sort-up'];
    } else {
      return ['fas', 'sort'];
    }
  }

  busquedaAvanzada(){
    _.each(this.fichaFilter,(filter,key)=>{
      if((key==="ficId" || key === "evaId" || key === "conId" || key ==="sede" || key==="idcurso")){
        if(filter !==null && filter !== ""){
          this.page.params = this.page.params.set(`filter[${key}]`, filter.toString());
        }
        else{
          this.page.DelParams(`filter[${key}]`); 
        }
      }
      else{
        if((key!=="ficId" && key !== "evaId" && key !== "conId" && key!=="sede" && key!=="idcurso")){
          if(filter !==null && filter !== ""){
            this.page.params = this.page.params.set(`filter[${key}][like]`, filter.toString());
          }
          else{
            this.page.DelParams(`filter[${key}][like]`); 
          }
        }
        else{ 
          if( key ==="sede" || key==="idcurso"){
            if(filter !==null && filter !== ""){
              this.page.params = this.page.params.set(`filter[params][${key}]`, filter.toString());
            }
            else{
              this.page.DelParams(`filter[params][${key}]`); 
            }
          }
        }
      }
    })
    this.getFichas();
  }

  limpiar(){
    this.page.params = new HttpParams();
    this.fichaFilter = new FichaFilter();
    this.getFichas();
  }
  open(content, ficha){
    this.fichaTemp = ficha;
    this.modalRef =this.modal.open(content, {size:'xl'});
  }

  close(){
    this.modalRef.close();
  }

  async guardar(event){
    this.toastrRef = this.toastService.info("guardando...")
    const trabajador = event.trabajador;
    const ficha = event.ficha;
    const params = event.params
    // const contratista = event.contratista;
    // if(Object.keys(contratista).length>0 && contratista.id===undefined){
    //   const response = await this.contratistaService.getIdentity(event.contratista).toPromise();
    //   event.ficha.con_id = response.id;
    // }
    try{
      if(ficha.params!==null){
        ficha.params.data = params;
        await Promise.all([
          this.trabajadorService.Update(trabajador.tra_id ,trabajador).toPromise(),
          this.fichaParamsService.Update(ficha.params.id,ficha.params).toPromise()
          // this.fichaService.Update(ficha.fic_id,ficha).toPromise()
        ])
      }
      else{
        let newParams = new FichaParams();
        newParams.content = event.params;
        newParams.fic_id = ficha.fic_id;
        await Promise.all([
          this.trabajadorService.Update(trabajador.tra_id ,trabajador).toPromise(),
          this.fichaParamsService.Create(newParams).toPromise()
        ])
      }
      this.modalRef.close();
      this.toastService.remove(this.toastrRef);
      this.toastService.success("información actualizada");
      this.init();
    }
    catch{
      this.toastService.error("No se guardaron los cambios","ERROR")
    }
  }

  getPdf(ficha) {
    const toastrRef = this.toastService.info('Generando PDF...', 'PDF');
    this.reportService.getFichaById(ficha)
      .subscribe(
        response => {
          const a = document.createElement('a');
          const filePDF = new Blob([response], { type: 'application/pdf' });
          const fileURL = window.URL.createObjectURL(filePDF);
          a.href = fileURL;
          a.download = 'REP SEG ' + ficha + '.pdf';
          a.click();
          this.toastService.remove(toastrRef.toastId);
          this.toastService.success('PDF generado exitosamente.','PDF');
        }, () => {
          this.toastService.remove(toastrRef.toastId);
          this.toastService.error('Falla al generar PDF.', 'PDF');
        });
  }

  async downloadExcel() {
    try {
      const toastrRef = this.toastService.info('Generando Reporte masivo...', 'REPORTE');
      const response = await this.senaService.GetReporteMasivo().toPromise()
      const date = moment().format('DD-MM-YYYY hh.mm.ss')
      FileSaver.saveAs(new Blob([response]), `Reporte Sena ${date}.xlsx`)
      this.toastService.remove(this.toastrRef)
      this.toastService.success("Se ha descargado la planilla")
    } catch (error) {
      this.toastService.remove(this.toastrRef)
      this.toastService.warning('Ha ocurrido un problema, verifique la conexión a internet', error.message)
    }
  }

  async onScroll() {
    if (this.fichas!==undefined && this.fichas.length < this.page.total) {
      this.page.page++;
      const { body, headers } = await this.senaService.GetFichas(this.page.params).toPromise();
      this.page.Load(headers);
      this.fichas.push(...body);
    }
  }

}
