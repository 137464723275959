<div class="card mb-3">
    <div class="card-body">
        <h5 class="card-title">Búsqueda Avanzada</h5>
        <!-- <h4>Búsqueda Avanzada</h4> -->
        <form #search="ngForm" (ngSubmit)="submit()">
            <div class="form-row mb-3">
                <div class="col-xl-3 col-md-6 col-sm-12">
                    <label for="rutSearch">Cédula de Ciudadanía</label>
                    <input placeholder="ingrese cédula de ciudadanía..." class="form-control" type="text" id="rutSearch"
                        name="rutSearch" [(ngModel)]="fichaFilter.traRut">
                </div>
                <div class="col-xl-3 col-md-6 col-sm-12">
                    <label for="nombreSearch">Nombre</label>
                    <input placeholder="ingrese nombre..." class="form-control" type="text" id="nombreSearch"
                        name="nombreSearch" [(ngModel)]="fichaFilter.traNombre">
                </div>
                <div class="col-xl-3 col-md-6 col-sm-12">
                    <label for="paternoSearch">Apellido Paterno</label>
                    <input placeholder="ingrese apellido paterno  ..." class="form-control" type="text"
                        id="paternoSearch" name="paternoSearch" [(ngModel)]="fichaFilter.traPaterno">
                </div>
                <div class="col-xl-3 col-md-6 col-sm-12">
                    <label for="maternoSearch">Apellido Materno</label>
                    <input placeholder="ingrese apellido materno ..." class="form-control" type="text"
                        id="maternoSearch" name="maternoSearch" [(ngModel)]="fichaFilter.traMaterno">
                </div>
                <div class="col-xl-3 col-md-6 col-sm-12">
                    <label for="mailSearch">Correo</label>
                    <input placeholder="ingrese correo ..." class="form-control" type="text" id="mailSearch"
                        name="mailSearch" [(ngModel)]="fichaFilter.traMail">
                </div>
                <div class="col-xl-3 col-md-6 col-sm-12">
                    <label for="sedeSearch">Sede</label>
                    <input placeholder="ingrese sede ..." class="form-control" type="text" id="sedeSearch"
                        name="sedeSearch" [(ngModel)]="fichaFilter.sede">
                </div>
                <div class="col-xl-3 col-md-6 col-sm-12">
                    <label for="idCurso">Código Curso</label>
                    <input placeholder="ingrese código de curso ..." class="form-control" type="text" id="idCurso"
                        name="idCurso" [(ngModel)]="fichaFilter.curso">
                </div>
                <div class="col-xl-3 col-md-6 col-sm-12">
                    <label for="evaluacionSearch">Curso</label>
                    <select class="form-control" id="evaluacionSearch" name="evaluacionSearch"
                        [(ngModel)]="fichaFilter.evaId">
                        <option></option>
                        <option *ngFor="let evaluacion of evaluaciones" value="{{evaluacion.eva_id}}">
                            {{evaluacion.nombre}}</option>
                    </select>
                </div>
                <!-- <div class="col-xl-3 col-md-6 col-sm-12 form-group">
                    <label for="contratistaSearch">Empresa</label>
                    <select class="form-control" id="contratistaSearch" name="contratistaSearch" [(ngModel)]="fichaFilter.conId">
                        <option></option>
                        <option *ngFor="let contratista of contratistas" value="{{contratista.id}}">{{contratista.nombre_corto}}</option>
                    </select>
                </div> -->
            </div>
            <button type="reset" class="btn btn-secondary  float-md-right" (click)="limpiar.emit({})">
                <fa-icon [icon]="['fas','eraser']"></fa-icon> Limpiar
            </button>
            <button type="submit" class="btn btn-primary mr-3 float-md-right">
                <fa-icon [icon]="['fas','search']"></fa-icon> Buscar
            </button>
        </form>
    </div>
</div>
<!-- </div> -->