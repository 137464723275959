export class User {
    cargo?: string
    creacion?: string
    email?: string
    modificacion?: string
    nacimiento?: string
    nombre?: string
    rut?: string
    username?: string
}
