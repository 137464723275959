<div class="card mb-3">
    <div class="card-body">
        <h5 class="card-title">Búsqueda Avanzada</h5>
        <form #formSearch="ngForm" (ngSubmit)="onSearch()">
            <div class="form-row">
                <div class="col-md-4 mb-3">
                    <label for="nombre">Cédula</label>
                    <input type="text" class="form-control" id="cedula" name="cedula" [(ngModel)]="cedula"
                        placeholder="Ingrese el numero de cédula ...">
                </div>
                <div class="col-md-4 mb-3">
                    <label for="validationDefault04">Fecha Inicio</label>
                    <div class="input-group">
                        <input class="form-control" placeholder="yyyy-mm-dd" name="dp" [(ngModel)]="from" ngbDatepicker
                            #dp="ngbDatepicker">
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="dp.toggle()" type="button">
                                <fa-icon [icon]="['fas','calendar']"></fa-icon>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 mb-3">
                    <label for="validationDefault04">Fecha Término</label>
                    <div class="input-group">
                        <input class="form-control" placeholder="yyyy-mm-dd" name="dp" [(ngModel)]="to" ngbDatepicker
                            #d="ngbDatepicker">
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary" (click)="d.toggle()" type="button">
                                <fa-icon [icon]="['fas','calendar']"></fa-icon>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 mb-3">
                    <label for="nombre">Nombre</label>
                    <input type="text" class="form-control" id="nombre" name="nombre" [(ngModel)]="nombre"
                        placeholder="Ingrese el nombre ...">
                </div>
                <div class="col-md-4 mb-3">
                    <label for="paterno">Paterno</label>
                    <input type="text" class="form-control" id="paterno" name="paterno" [(ngModel)]="paterno"
                        placeholder="Ingrese el apellido paterno ...">
                </div>
                <div class="col-md-4 mb-3">
                    <label for="materno">Materno</label>
                    <input type="text" class="form-control" id="materno" name="materno" [(ngModel)]="materno"
                        placeholder="Ingrese el apellido materno ...">
                </div>
                <div class="col-md-3 mb-3">
                    <label for="sede">Sede</label>
                    <input type="text" class="form-control" id="sede" name="sede" [(ngModel)]="sede"
                        placeholder="Ingrese la sede ...">
                </div>
                <div class="col-md-3 mb-3">
                    <label for="validationDefault04">Estado</label>
                    <ng-select id="estado" name="estado" [items]="[{key:'1',label:'Activo'},{key:'0',label:'Inactivo'}]"
                        bindLabel="label" bindValue="key" [(ngModel)]="proId" placeholder="Seleccione el estado ...">
                    </ng-select>
                </div>
                <div class="col-md-3 mb-3">
                    <label for="validationDefault04">Curso</label>
                    <ng-select id="project" name="project" [items]="projects" bindLabel="header" [multiple]="true"
                        [closeOnSelect]="false" [searchable]="true" bindValue="id" [(ngModel)]="proId"
                        placeholder="Seleccione curso...">
                    </ng-select>
                </div>
                <div class="col-md-3 mb-3">
                    <label for="curCodigo">Codigo del curso</label>
                    <input type="text" class="form-control" id="curCodigo" name="curCodigo" [(ngModel)]="curso"
                        placeholder="Ingrese el codigo del curso ...">
                </div>

            </div>
            <button type="reset" class="btn btn-secondary  float-md-right" (click)="search.emit({})">
                <fa-icon [icon]="['fas','eraser']"></fa-icon> Limpiar
            </button>
            <button type="submit" class="btn btn-primary mr-3 float-md-right">
                <fa-icon [icon]="['fas','search']"></fa-icon> Buscar
            </button>
        </form>
    </div>
</div>