import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { User } from '../../class/user';
import { UserProfileService } from '../../_services/user-profile.service';

@Component({
  selector: 'app-edit-user-modal',
  templateUrl: './edit-user-modal.component.html',
  styleUrls: ['./edit-user-modal.component.scss']
})
export class EditUserModalComponent implements OnInit {
  @Input() user:User
  @Output() cerrar = new EventEmitter();
  @Output() guardar = new EventEmitter();
  constructor(private perfilService:UserProfileService) { }

  public changePassword = false;
  nombreVacio = false;
  config = {
    animated: true
  };

  userVerify = {
    username: true
  };

  passVerify = {
    pass1: true,
    pass2: true,
    coindicencia: true
  };

  passwords = {
    pass1: '',
    pass2: ''
  };
  ngOnInit(): void {
    this.getPerfil();

  }

  getPerfil() {
    this.perfilService.Identity()
      .subscribe(data => {
        this.user = data        
      });
  }

  async save() {
    if (this.verifyAll()) {
      if (this.changePassword) {
        this.user['password'] = this.passwords.pass1;
      }
      if (this.user.nombre !== '' && this.user.username !== '') {
        this.guardar.emit(this.user);
      }
    } else {
      this.trimInputText('nombre');
      this.verifyPass('pass1');
      this.verifyPass('pass2');
    }
  }

  trimInputText(toVerifyKeyInputText: string) {
    this.user[toVerifyKeyInputText] = this.user[toVerifyKeyInputText];
    if (toVerifyKeyInputText == 'nombre') {
      if (this.user[toVerifyKeyInputText] == '') {
        this.nombreVacio = true;
      } else {
        this.nombreVacio = false;
      }
    }
  }

  verifyPass(sKey: string) {
    if (this.passwords[sKey].length >= 6) {
      this.passVerify[sKey] = true;
    } else {
      this.passVerify[sKey] = false;
    }
    if (this.passwords.pass1 === this.passwords.pass2) {
      this.passVerify.coindicencia = true;
    } else {
      this.passVerify.coindicencia = false;
    }
  }

  verifyAll(): boolean {
    if (!this.changePassword) {
      return true;
    }

    if (this.passwords.pass1.length >= 6) {
      if (this.passwords.pass2.length >= 6) {
        if (this.passwords.pass1 === this.passwords.pass2) {
          return true;
        }
        return false;
      }
      return false;
    }
    return false;
  }

}
