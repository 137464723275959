import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GoogleChartInterface } from 'ng2-google-charts';
import { Ficha } from 'projects/sena/src/app/class/ficha';
import * as _ from 'lodash';

@Component({
  selector: 'app-ficha-reporte',
  templateUrl: './ficha-reporte.component.html',
  styleUrls: ['./ficha-reporte.component.scss']
})
export class FichaReporteComponent implements OnInit {
  @Input() fichaTemp:Ficha;
  @Output() cerrar = new EventEmitter();
  ficha:Ficha;
  data=new Array();
  loaded= false;
  lastWindowsSize = 0;
  mensaje = "";
    // Pie
    public pieChart: GoogleChartInterface = {
      chartType: 'PieChart',
      dataTable: this.data,
      options: {
        title: 'Resultados',
        animation: {
          duration: 900,
          startup: true,
          easing: 'out',
        },
      },
      refreshInterval:2,
    };

  constructor() { }

  ngOnInit(): void {
    this.ficha = {...this.fichaTemp};
    this.consejo();
    this.dataChart();
  }

  dataChart(){
    let correctas = 0;
    let incorrectas = 0;
    _.each(this.ficha.alternativas, (alternativa)=>{
      (alternativa.correcta==="SI")?correctas++:incorrectas++;
    })
    this.data.push(['Aprobación', 'Respuestas'])
    this.data.push(['correctas',correctas])
    this.data.push(['incorrectas',incorrectas])
    this.loaded=true;
  }

  async onResize(event){
    // if(this.lastWindowsSize ==0 || event.target.innerWidth >= (this.lastWindowsSize+60)|| event.target.innerWidth <=(this.lastWindowsSize-60))
      if(this.pieChart!=null && this.pieChart.component !=null){
        this.pieChart.component.draw();
        // this.lastWindowsSize = event.target.innerWidth;
      }
  }


  consejo(){
    if(this.ficha.calificacion===1){
      this.mensaje =`El trabajo duro siempre es recompensado. De acuerdo a los resultados obtenidos se logra evidenciar 
      que el estudiante posee las competencias para detectar, identificar y percibir los diferentes riesgos asociados a 
      la actividad, lo que nos indica que el trabajador tiene la capacidad, esta alerta, conoce los riesgos de su 
      cargo y de la actividad que está realizado.`;
    }
    else{
        if(this.ficha.calificacion>=0.8){
            this.mensaje = `La motivación nos impulsa a comenzar y el hábito nos permite continuar. De acuerdo a los 
            resultados obtenidos se logra evidenciar que el estudiante ha tenido una calificación MEDIA. Se 
            recomienda repasar nuevamente los procedimientos para el ingreso a espacios confinados. Sugerimos enviar a 
            evaluación al estudiante una vez realizadas estas condiciones, ya que es muy probable que incurre en los mismos 
            errores durante la próxima evaluación.`;
        }
        else{
            this.mensaje = `La grandeza nace de pequeños comienzos. De acuerdo a los resultados obtenidos se logra evidenciar 
            que el estudiante ha tenido una calificación BAJA. Lo cual, nos indica que tiene una alta probabilidad de que un 
            incidente pueda ocurrir y de acarrear posibles consecuencias. Se recomienda repasar nuevamente los procedimientos. 
            Sugerimos no enviar a evaluación al estudiante en estas condiciones, ya que es muy probable que repruebe la 
            evaluación nuevamente.`;
        }
    }
  }

  

}
