import { Component, HostListener, Input } from '@angular/core';
import { Project } from '../../_class/project';
import { UnityService } from '../../_services/unity.service';

@Component({
  selector: 'app-library-view',
  templateUrl: './library-view.component.html',
  styleUrls: ['./library-view.component.scss']
})
export class LibraryViewComponent {

  @Input() model: Project;
  unityInstance: any;
  progress: number

  constructor(
    public unitySevice: UnityService
  ) { }

  // // ngOnInit(): void {
  // //   this.progress = 0;
  // //   if (this.model) {
  // //     this.loadProject(this.model.builds[0]?.content.configFile);
  // //   } else {
  // //     console.log("Nada a cargar aun")
  // //   }
  // // }

  // @HostListener("receiveMessageFromUnity",[])
  // public receiveMessageFromUnity(messageValue: string) {
  //   console.log('receiveMessageFromUnity', messageValue);
  // }


  public loadProject(url) {
    this.unityInstance = this.unitySevice.load("unityContainer", url, {
      onProgress: (instance, progress) => {
        this.progress = progress
        if (progress == 1) {
          console.log({ instance: instance })
        }
      }
    });
  }

  // public loadProject(path) {
  //   console.log("Comienza a cargar", path)
  //   if (this.unityInstance) {
  //     this.Destroy();
  //   }
  //   this.unityInstance = window['UnityLoader'].instantiate('unityContainer', path, {
  //     onProgress: (instance, progress) => {
  //       this.progress = progress
  //       if (progress == 1) {
  //         console.log("Instancia", { instance })
  //       }
  //     },
  //     Module: {
  //       onQuit: () => {
  //         console.log("Unity has quit")
  //       },
  //       errorhandler: (err) => {
  //         console.log("error", err)
  //       }
  //     }
  //   });
  // }

  // public Destroy() {
  //   this.unityInstance.Quit()
  //   this.unityInstance = null;
  //   this.progress = 0;
  //   console.log("Unity cerrado")
  // }



  // public onProgress(instance, progress) {
  //   this.progress = progress
  //   console.log({ instance })
  //   console.log({ progress })
  // }

  // public fullScreen() {
  //   this.unityInstance.SetFullscreen(1)
  // }


  // public saluda()
  // {
  //   console.log("hola")
  // }
}
