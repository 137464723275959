<div class="page-header">
    <h1>Administración <small>Inscripciones</small></h1>
</div>

<div class="row mb-3">
    <div class="col-md-12 col-xl-6">
        <form class="form-inline" #formSearchCedula="ngForm" (ngSubmit)="search({cedula:cedula.value})">
            <label for="cedula">N° Cédula</label>
            <input class="form-control mx-sm-3" type="text" name="cedula" id="cedula" #cedula
                placeholder="Ingrese cédula ..." [required]="true">
            <button type="submit" class="btn btn-primary mr-3">
                <fa-icon [icon]="['fas','search']"></fa-icon> Buscar
            </button>
            <button type="reset" class="btn btn-secondary" (click)="search({})">
                <fa-icon [icon]="['fas','eraser']"></fa-icon> Limpiar
            </button>
        </form>
    </div>
    <div class="col-md-12 col-xl-6">
        <form class="form-inline float-md-right">
            <button type="reset" class="btn btn-success mr-3" (click)="open(createInscription,{size:'xl'})">
                <fa-icon [icon]="['fas','user-plus']"></fa-icon> Agregar
            </button>
            <button type="reset" class="btn btn-secondary mr-3"
                (click)="getFirstData();toastr.info('Información actualizada')">
                <fa-icon [icon]="['fas','redo']"></fa-icon> Actualizar
            </button>
            <button type="reset" class="btn btn-primary" (click)="showSearch=!showSearch">
                <fa-icon [icon]="['fas','search']"></fa-icon> Búsqueda avanzada
            </button>
        </form>
    </div>
</div>

<div class="card mb-3" [ngbCollapse]="loading.collapse">
    <div class="card-body">
        <h5 class="card-title">Cargando alumnos...
            <div *ngIf="loading.current != loading.total" class="spinner-border text-primary float-right" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </h5>
        <p class="card-text text-muted">
            En este momento se encuentra cargando los alumnos, es importanto no cerrar esta
            pestaña durante este proceso.
        </p>
        <p>
            <ngb-progressbar type="primary" [value]="loading.current" [max]="loading.total" [striped]="loading.tripped"
                [animated]="loading.animated">
                {{loading.text()}}</ngb-progressbar>
        </p>

        <p class="card-text">
            Enviando : {{loading.current}} de {{loading.total}}<br>
            Exitoso : {{loading.current- loading.fail}}
            Fallaron : {{loading.fail}}
        </p>


        <table class="table" *ngIf="inscriptionsMailFail.length!=0">
            <thead>
                <tr>
                    <th>Alumno</th>
                    <th>Email</th>
                    <th>Error</th>
                </tr>
            </thead>
            <tbody>
                <ng-template ngFor let-item [ngForOf]="inscriptionsMailFail" let-i="index">
                    <tr>
                        <td>
                            {{item?.inscription?.content?.ficha?.trabajador?.rut}}
                            {{item?.inscription?.content?.ficha?.trabajador?.nombre}}
                            {{item?.inscription?.content?.ficha?.trabajador?.paterno}}
                            {{item?.inscription?.content?.ficha?.trabajador?.materno}}
                        </td>
                        <td>
                            {{item?.inscription?.content?.ficha?.trabajador?.mail}}
                        </td>
                        <td>
                            <ul>

                                <ng-template ngFor let-error [ngForOf]="item?.error?.error" let-i="index">
                                    <li>
                                        {{error.message}}
                                    </li>
                                </ng-template>
                            </ul>

                        </td>
                    </tr>
                </ng-template>
            </tbody>
        </table>
        <button class="btn btn-primary float-right" type="button" (click)="closeShowProgress()">Cerrar</button>
    </div>
</div>

<div class="row">
    <div class="col" #collapse="ngbCollapse" [(ngbCollapse)]="!showSearch">
        <app-inscription-search [projects]="projects" (search)="search($event)"></app-inscription-search>
    </div>
</div>

<div class="row" infinite-scroll [infiniteScrollDistance]="2" (scrolled)="onScroll()">
    <div class="col">
        <div class="table-responsive">
            <table class="table table-bordered table-hover">
                <thead>
                    <ng-template ngFor let-item [ngForOf]="sort.menu" let-i="index">
                        <th *ngIf="item.sort as sort; else elseBlock"
                            [ngClass]="{ 'text-primary': sort.name == item.sort }" [ngStyle]="{ cursor: 'pointer'}"
                            (click)="setOrder(item.sort)">
                            <fa-icon [icon]="iconOrder(item.sort)"></fa-icon> {{item.title}}
                        </th>
                        <ng-template #elseBlock>
                            <th>{{item.title}}</th>
                        </ng-template>
                    </ng-template>
                    <!-- <th scope="col" *ngFor="let item of sort.menu"
                        [ngClass]="{ 'text-primary': sort.name == item.sort }" [ngStyle]="{ cursor: 'pointer'}"
                        (click)="setOrder(item.sort)">
                        <fa-icon [icon]="iconOrder(item.sort)"></fa-icon> {{item.title}}
                    </th> -->
                </thead>
                <tbody>
                    <tr [ngClass]="{tachado:!item.status}" *ngFor="let item of models;trackBy: trackBy">
                        <td>{{item.content?.ficha?.trabajador?.rut}}</td>
                        <td>
                            {{item.content?.ficha?.trabajador?.nombre}}
                            {{item.content?.ficha?.trabajador?.paterno}}
                            {{item.content?.ficha?.trabajador?.materno}}
                        </td>
                        <td>{{item.content?.ficha?.params?.sede}}</td>
                        <td>{{item.project.header}}</td>
                        <td>{{item.content?.ficha?.params?.curso}}</td>
                        <td>{{item.content?.ficha?.params?.mail?.status}}</td>
                        <td>{{item.creado|date:"medium"}}</td>
                        <td>
                            <div class="d-flex justify-content-around">
                                <!-- <button class="btn btn-primary btn-sm" (click)="model=item; open(deleteInscription)">
                                    <fa-icon [icon]="['fas','user-edit']"></fa-icon>
                                </button> -->
                                <button class="btn btn-danger btn-sm" (click)="model=item; open(deleteInscription)">
                                    <fa-icon [icon]="['fas','user-times']"></fa-icon>
                                </button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

<app-btn-scroll></app-btn-scroll>

<ng-template #createInscription let-modal>
    <app-inscription-create [projects]="projects" [contratistas]="contratistas" (save)="save($event)" (close)="close()">
    </app-inscription-create>
</ng-template>

<ng-template #deleteInscription let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Desactivar Inscripción</h4>
        <button type="button" class="close" aria-label="Close" (click)="close()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>¿Esta seguro que desea desactivar inscripción para {{model.content.ficha.trabajador.nombre}}?</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-danger" (click)="disable()">Desactivar</button>
    </div>
</ng-template>